@import 'styles/mixins/media-queries';
@import 'styles/colors-2023';
@import 'styles/layout-tokens';
@import 'styles/mixins/fonts-2023';
@import 'styles/mixins/button-styles-2023';

.container {
	width: 100%;
	height: 100%;

	position: relative;

	background: linear-gradient(180deg, #f5f5f7 0%, #eaeceb 100%);
	color: $neutral-black;

	@include sm-down {
		background: $neutral-white;
		padding-bottom: 2rem;
	}
}

.inner {
	width: 100%;
	height: 100%;

	padding: 5rem;

	display: flex;
	justify-content: center;
	align-items: center;

	@include sm-down {
		background: linear-gradient(180deg, #f5f5f7 0%, #eaeceb 100%);
		padding: 4rem;
		aspect-ratio: 3/2;
	}

	border-radius: 6px;
}

.layout_image {
	height: 100%;
	width: 100%;

	img {
		height: 100%;
		width: 100%;
	}
}

.item_list {
	position: absolute;

	width: 100%;
	top: 1.25rem;
	left: 1.25rem;
	display: flex;
	gap: 1rem;

	list-style: none;

	align-items: center;
	margin: 0;

	@include sm-down {
		position: static;
		margin-top: 0.5rem;
		gap: 0.5rem;
		justify-content: center;

		flex-wrap: wrap;
	}
}

.item {
	transition: opacity 0.25s ease;
}

.item_button {
	border: none;

	border-radius: 3px;
	background: $neutral-white;
	box-shadow: transparentize($neutral-black, 0.9) 0 0 0 1px;

	padding: 0.25rem 1.25rem;

	@include body8;
	color: $neutral-black;

	white-space: nowrap;
}

.layout {
	margin: 0;
	display: grid;
	height: 100%;
	width: 100%;

	list-style: none;

	align-content: center;
	justify-items: center;
	align-items: center;
	justify-content: center;
}

.layout_item {
	transition: opacity 0.25s ease;

	height: 100%;
	width: 100%;

	position: relative;

	picture {
		position: absolute;
		top: 0;
		left: 0;
	}

	picture,
	img {
		height: 100%;
		width: 100%;
	}
}

.cover_hub_mattress_power-cord {
	grid-template-rows: 3fr 2fr;
	grid-template-columns: 2fr 1fr 1fr;

	.mattress {
		grid-row: 1 / span 2;
		grid-column: 1;
	}

	.cover {
		grid-row: 1 / span 2;
		grid-column: 2;
	}

	.hub {
		grid-row: 1;
		grid-column: 3;
	}

	.power-cord {
		grid-row: 2;
		grid-column: 3;
	}
}

.cover_hub_power-cord {
	grid-template-rows: 2fr 1fr;
	grid-template-columns: 1fr 1fr;

	.cover {
		grid-row: 1 / span 2;
		grid-column: 1;
	}

	.hub {
		grid-row: 1;
		grid-column: 2;
	}

	.power-cord {
		grid-row: 2;
		grid-column: 2;
	}
}
