@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.badge_base {
	display: inline-block;
	padding: 0.25rem 0.75rem;
	border-radius: 0.25rem;

	color: var(--text-color);
	background-color: var(--background-color);
	box-shadow: var(--border-color) 0 0 0 1px inset;
	backdrop-filter: blur(var(--backdrop-blur-amount));
}
