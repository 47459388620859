@import 'styles/constants';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';

.label {
	position: relative;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	justify-content: center;

	text-align: left;

	box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 0 1px;
	background-color: #fff;

	border-radius: 5px;

	min-width: 100%;

	padding: 16px;

	&:hover {
		cursor: pointer;
		box-shadow: inset $brand-secondary 0 0 0 2px;
	}

	@include sm-up {
		min-width: 100px;
		min-height: 40px;
		text-align: center;
		padding: 10px;
		flex: 1;
	}

	:global(.badge_v2) {
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.3px;

		background: linear-gradient(113.7deg, #1862ff -64.08%, #ff00d6 220.18%);
		color: $brand-primary;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

	span.price_label {
		font-size: 16px;
	}
}

input:focus-visible + .label {
	box-shadow: $brand-secondary 0 0 5px 5px !important;
	background-color: #f2f4f6;
}

input:checked + .label {
	box-shadow: inset $brand-primary 0 0 0 2px;
	background-color: #f2f4f6;
}

.title {
	display: block;
	font-family: var(--neue-montreal), sans-serif;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 2px;
	font-weight: 700;

	@include sm-up {
		margin-top: 2px;
	}
}

.fieldset {
	border: none;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}
