.main_wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid #d8d8d8;
	background-color: #fff;
	align-items: center;
	padding: 35px 69px;

	img {
		width: 50px;
		margin-bottom: 29px;
	}
}

.title {
	text-align: center;
	margin-bottom: 16px;
}

.description {
	text-align: center;
	margin-bottom: 24px;
}

.shop_cover_cta {
	margin-bottom: 24px;
	margin-left: 0;
	margin-right: 0;
}

.ship_to_us {
	text-align: center;
}
