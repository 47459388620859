@import 'styles/mixins/fonts';
@import 'styles/colors';
@import 'styles/mixins/media-queries';
@import '../_mixins/buttons';

.container {
	transition: all 0.25s ease;

	* {
		transition: all 0.25s ease;
	}

	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	height: 94px;
	width: 100vw;
	background: #f2f4f6;
	color: black;

	padding: 18px 18px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	z-index: 5;

	box-shadow: rgb(0 0 0 / 50%) 0 0 8px 0;
}

.container_collapsed {
	height: 100px;
	padding: 13px 20px;
}

.image_container {
	@include xs-only {
		display: none;
	}

	display: block;
	height: 100%;
	overflow: hidden;

	justify-self: end;
}

.product_container {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.price_text {
	font-size: 14px;
	line-height: 140%;
	font-weight: 700;
	margin: 0 0.5ch 0 0;
}

.pricing_container {
	display: flex;
	flex-direction: column;

	div {
		display: flex;
	}
}

.compare_price {
	color: #adb3c5;
	font-weight: 400;
	text-decoration: line-through;
}

.save_price {
	text-transform: uppercase;

	font-size: 12px;
	line-height: 24px;
	font-weight: 700;

	letter-spacing: 0.5px;

	color: $brand-primary;
}

.atc_button.atc_button {
	font-weight: 500;
	letter-spacing: 0.5px;

	margin: 0;
}

.shop_pay_button.shop_pay_button {
	display: flex;
	align-items: center;
	text-align: center;

	padding-left: 6px;
	padding-right: 6px;

	background-color: rgba(91, 49, 245, 0.1);
	border-color: #5a31f4;

	&:hover {
		background-color: rgba(71, 38, 191, 0.3);
		border-color: #4726bf;
		color: #5a31f4;
	}

	&:disabled {
		background-color: rgba(71, 38, 191, 0.3);
		border-color: #957af5;
		color: rgba(255, 255, 255, 0.5);

		img {
			filter: brightness(100);
			opacity: 0.5;
		}
	}

	img {
		max-height: 20px;
		height: 20px;
	}
}

.atc_button_small {
	width: 101px;
	padding: 13px 27px;

	font-weight: 700;
	font-size: 12px;
	line-height: 22px;

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: uppercase;

	color: $ui-white;
}

.atc_button_secondary {
	background-color: transparent;
	color: $brand-primary;
}

.financing_text {
	font-weight: 700;
	font-size: 14px;
	line-height: 140%;
	margin: 0;
}

.variant_text {
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	margin: 0;

	* {
		color: black;
	}
}

.sticky_button_container {
	display: flex;
	gap: 6px;
	width: 50%;
	justify-content: flex-end;
	@include max(420px) {
		width: 60%;
	}
}

.double_button_container {
	display: flex;
	gap: 6px;
	flex: 1;
	width: 100%;

	@media (max-width: 321px) {
		> * {
			width: 160px;
			padding: 5px !important;
		}
		> button {
			margin: 0;
		}
	}

	@media (min-width: 322px) {
		> * {
			width: 0;
			flex: 1 1 0;
			padding-left: 6px !important;
			padding-right: 6px !important;
		}
	}
}
