@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/mixins/pod-3-fonts';

@keyframes over-scale-element {
	0% {
		max-width: 0;
	}

	100% {
		max-width: 300px;
	}
}

.container {
	@include md-up {
		padding: 0 11.5px;
	}
}

.h2 {
	@include h2-mobile;
	text-align: center;

	@include sm-up {
		@include pod-3-header-2;
		margin: 0 auto 30px;
		max-width: 850px;
	}

	@include xs-only {
		max-width: unset;
		margin-bottom: 30px;
	}
}

.h2_ab {
	@include h2-mobile;
	text-align: center;

	@include sm-up {
		@include pod-3-header-2;
		margin: 0 auto 30px;
		max-width: 850px;
	}

	@include xs-only {
		max-width: unset;
		margin-bottom: 30px;
		text-align: left;
	}
}

.features {
	max-width: 690px;
	margin: 60px auto 0;
	display: flex;
	justify-content: center;
	gap: 30px;
	position: relative;
	padding-bottom: 40px;

	@include xs-only {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 40px;
	}

	> small {
		@include pod-3-body-5;

		color: #797c8e;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		text-align: center;
	}

	> div {
		@include pod-3-body-4;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 5px;

		@include sm-down {
			@include pod-3-body-5;
		}
		span {
			max-width: 130px;
		}

		> img {
			width: 40px;
			max-height: 40px;
		}
	}
}

.features_ab {
	max-width: 690px;
	margin: 60px auto 0;
	display: flex;
	justify-content: center;
	gap: 30px;
	position: relative;
	padding-bottom: 40px;

	@include xs-only {
		margin-top: 40px;
	}

	> small {
		@include pod-3-body-5;

		color: #797c8e;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		text-align: center;
	}

	> div {
		@include pod-3-body-4;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 5px;

		@include sm-down {
			@include pod-3-body-5;
		}
		span {
			max-width: 130px;
		}

		> img {
			width: 40px;
			max-height: 40px;
		}
	}
}

.wrapper {
	margin: 0 auto;
	position: relative;

	&.is_active {
		.layer_reveal {
			> .layer_description {
				opacity: 1;
				animation: over-scale-element 0.5s ease-out;
				animation-fill-mode: forwards;
				transition: opacity 0.4s ease-out;

				&:nth-child(1) {
					animation-delay: 0;
					transition-delay: 0;
				}
				&:nth-child(2) {
					animation-delay: 0.1s;
					transition-delay: 0.1s;
				}
				&:nth-child(3) {
					animation-delay: 0.2s;
					transition-delay: 0.2s;
				}
				&:nth-child(4) {
					animation-delay: 0.3s;
					transition-delay: 0.3s;
				}
				&:nth-child(5) {
					animation-delay: 0.4s;
					transition-delay: 0.4s;
				}
				&:nth-child(6) {
					animation-delay: 0.5s;
					transition-delay: 0.5s;
				}
				&:nth-child(7) {
					animation-delay: 0.6s;
					transition-delay: 0.6s;
				}
				&:nth-child(8) {
					animation-delay: 0.7s;
					transition-delay: 0.7s;
				}
				&:nth-child(9) {
					animation-delay: 0.8s;
					transition-delay: 0.8s;
				}
				&:nth-child(10) {
					animation-delay: 0.9s;
					transition-delay: 0.9s;
				}
			}
		}
	}

	.layers_overlay {
		position: relative;
		overflow: hidden;

		> .layers {
			max-width: 850px;
			margin: 0 auto;
			display: block;

			@include sm-down {
				max-width: 70%;
				margin-left: auto;
				margin-right: 0;
			}
		}

		> .layers_ab {
			margin: 0 auto;
			display: block;
			max-width: 850px;
		}

		> .layer_description {
			opacity: 0;
			position: absolute;
			left: 16%;
			border-bottom: 3px solid #000000;
			width: min(250px, 33vw);

			&.reverse {
				text-align: right;
				bottom: 25%;
				left: 67%;

				.inner_box::after {
					left: -10px;
					right: auto;
				}
			}

			.inner_box {
				position: relative;
				font-size: 16px;
				color: #000;
				padding-bottom: 5px;
				white-space: nowrap;
				overflow: visible;

				&::after {
					content: '';
					right: -10px;
					bottom: -6px;
					background: #ffffff;
					border: 2px solid #000000;
					width: 11px;
					height: 11px;
					position: absolute;
					border-radius: 50%;
				}
			}

			@include md-only {
				left: 14%;
			}

			@include sm-down {
				left: 2.5%;
				width: 45%;

				> .inner_box {
					font-size: 14px;
				}
			}

			@include xs-only {
				> .inner_box {
					font-size: 11px;
					text-align: center;
					padding-bottom: 0;
				}
			}
		}
	}
}

.description {
	@include pod-3-body-4;
	margin: 0 auto;
	text-align: center;
	max-width: 620px;
	line-height: 1.4;
}

.description_ab {
	@include pod-3-body-4;
	margin: 0 auto;
	text-align: center;
	max-width: 620px;
	line-height: 1.4;

	@include xs-only {
		text-align: left;
	}
}
