@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	padding: 4rem;

	background: no-repeat center center url('https://eightsleep.imgix.net/assets/sleep-elixir-waitlist/cosmic-new-v3.png?auto=format,compress');
	background-size: cover;

	@include sm-down {
		padding: 2.5rem 1.5rem 40vh;
		justify-content: stretch;
		background-color: $black;
		background-position: bottom left;
		background-image: url('https://eightsleep.imgix.net/assets/sleep-elixir-waitlist/sweep-widget-bg-mobile.png-v3.jpg?auto=format,compress');
	}

	@include xs-only {
		background-size: contain;
	}

	min-height: 40rem;
}

.content {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	max-width: $max-width;
	margin-inline: auto;
}

.signup_container {
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
	backdrop-filter: blur(10px);
	display: grid;
	gap: 1.5rem;
	max-width: 560px;
	padding: 2.5rem;

	@include sm-down {
		padding: 2.5rem 1.5rem;
		width: 100%;
		margin-inline: auto;
	}

	@include xs-only {
		> h2 {
			max-width: 14ch;
		}
	}

	.bodytext {
		color: $text-grey-dark;
		margin: 0;
	}

	.boldtext {
		font-weight: bold;
	}

	.centertext {
		text-align: center;
	}

	.giveaway_container {
		background: transparent;
		width: 100%;
		margin: -10px auto 0;

		:global(.gw-flex.gw-flex-col.gw-rounded-md) {
			padding: 0;
		}

		div > :global(span.gw-font-bold) {
			color: #1862ff;
		}

		* {
			font-size: clamp(1rem, 0.8125rem + 0.468vw, 1.125rem) !important;
			font-family: var(--neue-montreal), sans-serif !important;
			font-weight: 400 !important;
			letter-spacing: clamp(0.01rem, 0rem + 0vw, 0.0112rem) !important;
			line-height: clamp(1.4rem, 1.1562rem + 0.6562vw, 1.575rem) !important;

			:global(.gw-text-2xl.gw-pb-2) {
				line-height: 1.25 !important;
			}
		}

		input {
			padding: 11px 14px !important;
			border-radius: 4px;
			border: 1px solid #a0a0a0;
		}
	}
}
