@import 'styles/mixins/media-queries';
@import 'styles/colors';

.container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #eeeeee;
	color: black;
	padding: 60px 0;
	transition: all 0.5s ease;
	transform: translate3d(0, 100%, 0);
	z-index: 100000000000000;
}

.container_visible {
	transform: translate3d(0, -0, 0);
}

.inner {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 1070px;
	margin-inline: auto;
	gap: 60px;
	justify-content: center;
	align-items: center;

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		gap: 30px;

		text-align: center;
	}
}

.text {
	h3 {
		font-size: 24px;
		line-height: 140%;
		margin-bottom: 10px;
	}

	p {
		font-size: 14px;
		line-height: 140%;
		max-width: 48ch;

		@include sm-down {
			max-width: 30ch;
		}

		margin-bottom: 0;
	}

	margin-inline: auto;
}

.close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	background: none;
	border: none;
	cursor: pointer;
	width: 2rem;
	height: 2rem;
}

.email_input {
	border-radius: 3px;

	background: transparent;
	position: relative;
	padding: 0;

	display: grid;
	grid-template-columns: 1fr auto;
	margin-inline: auto;

	width: 40ch;
	max-width: 40ch;
	@include sm-down {
		width: 80%;
	}

	input {
		background: none;
		border: none;
		font: inherit;
		outline-offset: 0;
		color: black;
		border-bottom: 1px solid #000;
		height: 3rem;

		&:focus {
			border-bottom: 1px solid $brand-primary;
			outline: none;
		}
	}

	button {
		position: absolute;
		right: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
		width: 2rem;
		height: 2rem;
		background: none;
		border: none;
		cursor: pointer;
		margin: 0;
		padding: 0;
	}

	> div {
		width: 32px;
		padding-top: 10px;
	}
}
