@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-phantom-grotesk';
@import 'styles/mixins/fonts-decorated';

.root {
	--gap: 2rem;
	background-color: $white;
	color: $black;
	padding-left: 3rem;
	padding-right: 3rem;
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	@include sm-down {
		padding-left: 1.5rem;
		padding-right: 1.5rem;

		--gap: 1rem;
	}

	> * {
		max-width: 1292px;
		margin: 0 auto;
		width: 100%;
	}
}

.header {
	text-align: center;

	p {
		color: $text-grey-dark;
		max-width: 40ch;
		margin-inline: auto;
	}
}

.outer {
	border-radius: 8px;
	overflow: hidden;
	position: relative;

	isolation: isolate;

	display: flex;

	@include md-up {
		max-height: clamp(20rem, 35vw, 35rem);
	}

	.content {
		color: $white;
		padding: 45px;
		display: flex;
		flex-direction: column;

		h3 {
			max-width: 400px;
		}

		@include xs-only {
			padding: 25px;

			h3 {
				max-width: 50vw;
			}
		}
	}

	&:nth-of-type(2),
	&:nth-of-type(4) {
		.content {
			justify-content: flex-end;
		}
	}

	&:nth-of-type(5) {
		h3 {
			color: black;
			max-width: 315px;

			@include sm-down {
				max-width: 50vw;
			}
		}
	}

	&:nth-of-type(5),
	&:nth-of-type(6) {
		.eyebrow {
			color: #929292;
		}

		.content {
			justify-content: flex-end;
		}
	}

	img {
		height: 100%;
	}
}

.full_grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: var(--gap);

	margin-top: 4rem;

	@include sm-down {
		margin-top: 2rem;
	}

	> div {
		@include md-up {
			min-height: 30rem;
			&:nth-child(1) {
				grid-column: 1 / span 3;
			}

			&:nth-child(2) {
				grid-column: 4 / span 3;
			}

			&:nth-child(3) {
				grid-column: 1 / span 4;
			}

			&:nth-child(4) {
				grid-column: 5 / span 2;
			}

			&:nth-child(5) {
				grid-column: 1 / span 2;
			}

			&:nth-child(6) {
				grid-column: 3 / span 4;
			}
		}
	}

	@include sm-down {
		grid-template-columns: 1fr;

		.outer {
			aspect-ratio: 1;
		}
	}

	> div {
		&:nth-child(3) svg {
			max-width: 277px;
			position: absolute;
			right: 3rem;
			top: 4rem;

			@include sm-down {
				right: 1rem;
				top: 50%;
				transform: translateY(-50%);
				max-width: 40vw;
			}
		}
	}
}

.elevationIcon {
	position: absolute;
	bottom: 3rem;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: 18px;

	> div {
		display: flex;
		column-gap: 18px;
		justify-content: center;

		@include xs-only {
			column-gap: 10px;
		}
	}

	svg {
		opacity: 0.3;

		&:nth-of-type(2) {
			opacity: 1;
		}
	}

	@include xs-only {
		font-size: 10px;
		bottom: 1rem;
		row-gap: 0;
		max-width: 40vw;
	}
}

.bg_image {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	z-index: -1;

	img {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
}

.eyebrow {
	@include eyebrow1;
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	margin: 0 0 0.5rem;

	@include sm-down {
		@include eyebrow2;
		margin: 0 0 0.5rem;
	}
}
