@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	text-align: center;

	display: flex;
	flex-direction: column;
	justify-content: center;

	align-items: center;
	padding: 10rem 1.25rem 12rem;

	@include sm-down {
		padding: 4rem 1.25rem 8rem;
		row-gap: 6rem;
	}

	&.gray {
		background-color: $background-off-white;
	}
}

.heading {
	max-width: 85ch;
	margin-inline: auto;
	margin-bottom: 12rem;
	opacity: 1;
	translate: 0 50%;

	@include sm-down {
		margin-bottom: 0;
	}
}

.heading2 {
	max-width: 85ch;
	margin-inline: auto;
	margin-bottom: 0;
	opacity: 1;
	translate: 0;

	@include sm-down {
		margin-top: 4rem;
		margin-bottom: 0;
	}

	.content {
		opacity: 1;
		scale: 1;
		translate: none;
	}
}

.content {
	max-width: 500px;
	margin-inline: auto;
	opacity: 0;
	scale: 0.95;
	translate: 0 100%;
}

.text {
	color: $text-grey-dark;
}
