@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/mixins/theme';
@import 'styles/mixins/pod-3-fonts';

.financing_container {
	color: white;
	background-color: #1d1e22;
	padding: 90px;
	position: relative;

	@include xs-only {
		padding: 60px 12px 40px 12px;
	}

	:global(.content) {
		display: grid;
		place-items: center;
	}
}

.financing_content {
	text-align: center;
	font-size: 19px;

	@include sm-only {
		font-size: 16px;
	}

	@include xs-only {
		font-size: 13px;
	}

	p {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.5px;
		margin-bottom: 9px;
		@include md-up {
			font-size: 20px;
			margin-bottom: 12px;
		}
	}

	h3 {
		@include pod-3-header-4;
		margin-bottom: 33px;
		line-height: 1.4;
		@include xs-only {
			@include pod-3-header-5;
			line-height: 1.4;
			margin-bottom: 50px;
		}
	}

	.financing_items {
		display: flex;
		justify-content: center;
		column-gap: 30px;
		color: white;

		@include xs-only {
			column-gap: 20px;
			padding-left: 16px;
			padding-right: 16px;
		}

		> div {
			flex: 0 1 180px;
			font-size: 48px;
			font-weight: 400;
			display: flex;
			flex-direction: column;

			@include xs-only {
				font-size: 2.7em;
			}

			> div {
				margin-top: 10px;
				margin-bottom: 10px;
				display: flex;
				justify-content: center;
			}

			> span {
				font-size: 18px;
				margin-top: auto;
				margin-bottom: 0;
				text-transform: uppercase;
				white-space: nowrap;
				@include xs-only {
					font-size: 0.3em;
				}
			}

			&:nth-child(2) {
				border-right: 2px solid rgba(255, 255, 255, 0.3);
				border-left: 2px solid rgba(255, 255, 255, 0.3);
				padding: 0 30px;

				@include xs-only {
					padding: 0 20px;
				}
			}
		}
	}

	.cta_button {
		min-width: 0;
		margin-top: 45px;

		@include xs-only {
			width: 100%;
			max-width: 256px;
		}
	}

	.view_pods {
		margin-top: 26px;
		color: white;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.5px;

		@include xs-only {
			font-size: 15px;
			font-weight: normal;
		}

		img {
			vertical-align: middle;
			margin-left: 10px;

			@include xs-only {
				display: none;
			}
		}
	}
}

.affirm_logo {
	display: inline-block;

	max-height: 34px;
	max-width: 86px;
}

.prequalify {
	color: white;
	text-decoration: none !important;
	cursor: pointer;
	margin-top: 26px;
	font-weight: 700;
	font-size: 16px;
}

$text-arrow-space: 10px;
$shaft-width: 14px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.the_arrow {
	width: $shaft-width;
	transition: all 0.2s;

	&.pos_left {
		position: absolute;
		top: 50%;
		left: 0;

		> .shaft {
			width: 0;
			background-color: currentColor;

			&:before,
			&:after {
				width: 0;
				background-color: currentColor;
			}

			&:before {
				transform: rotate(0);
			}

			&:after {
				transform: rotate(0);
			}
		}
	}

	&.pos_right {
		> .shaft {
			width: $shaft-width;
			transition-delay: 0.2s;

			&:before,
			&:after {
				width: $arrow-head-width;
				transition-delay: 0.3s;
				transition: all 0.5s;
			}

			&:before {
				transform: rotate(40deg);
			}

			&:after {
				transform: rotate(-40deg);
			}
		}
	}

	> .shaft {
		background-color: currentColor;
		display: block;
		height: $shaft-thickness;
		position: relative;
		transition: all 0.2s;
		transition-delay: 0;
		will-change: transform;

		&:before,
		&:after {
			background-color: currentColor;
			content: '';
			display: block;
			height: $arrow-head-thickness;
			position: absolute;
			top: 0;
			right: 0;
			transition: all 0.2s;
			transition-delay: 0;
		}

		&:before {
			transform-origin: top right;
		}

		&:after {
			transform-origin: bottom right;
		}
	}
}

.animated_arrow {
	align-self: center;
	display: inline-block;
	text-decoration: none;
	position: relative;
	transition: all 0.2s;

	&:hover {
		text-decoration: none;

		> .the_arrow.pos_left {
			> .shaft {
				width: $shaft-width;
				transition-delay: 0.1s;
				background-color: currentColor;

				&:before,
				&:after {
					width: $arrow-head-width;
					transition-delay: 0.1s;
					background-color: currentColor;
				}

				&:before {
					transform: rotate(40deg);
				}

				&:after {
					transform: rotate(-40deg);
				}
			}
		}

		> .main {
			transform: translateX($shaft-width + $text-arrow-space);

			> .the_arrow.pos_right {
				> .shaft {
					width: 0;
					transform: translateX(200%);
					transition-delay: 0;

					&:before,
					&:after {
						width: 0;
						transition-delay: 0;
						transition: all 0.1s;
					}

					&:before {
						transform: rotate(0);
					}

					&:after {
						transform: rotate(0);
					}
				}
			}
		}
	}

	> .main {
		display: flex;
		align-items: center;
		transition: all 0.2s;

		> .text {
			margin: 0 $text-arrow-space 0 0;
			line-height: 1;
		}

		> .the_arrow {
			position: relative;
		}
	}
}

a.affirm_link {
	color: #999;
	font-size: 12.5px;
	text-decoration: none;
	font-weight: 400;

	> span {
		display: inline !important;
	}

	> svg:global(.affirmTransform) {
		height: 1.3em;
		max-height: 1.3em;
		transform: none;
		cursor: pointer;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.klarna_link {
	svg {
		width: 4em;
	}
}
