@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: sticky;

	width: 100%;
	height: calc(100svh - $promo-height - 70px - var(--promo-bar-translate, 0px));

	contain: paint;

	margin: 0;
	padding: 0;
	list-style: none;

	background: #f2f2f2;

	top: calc($promo-height + var(--promo-bar-translate, 0px) + 70px);

	transition: top $promo-transition-time $promo-transition-ease, height $promo-transition-time $promo-transition-ease;

	border-radius: 0;
}

.container > li {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	transition: opacity 0.25s ease-in-out;
}

.rails {
	position: relative;
	height: 100%;
	width: 100%;

	contain: paint;
}

.single_visual {
	position: relative;

	width: 100%;
	max-height: min(50vh, 1000px);

	&[data-current-index='0'] {
		aspect-ratio: 7 / 4;
	}
	aspect-ratio: 3 / 2;

	> * {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	background: #f2f2f2;

	.visual_image img {
		border-radius: 0 !important;
	}
}

.visual_image {
	display: block;
	width: 100%;
	height: 100%;

	> img {
		width: 100%;
		height: 100%;

		object-position: center;

		border-radius: 1rem;
	}
}

.stack {
	width: 100%;
	height: 100%;

	position: relative;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		transition: opacity 0.25s ease-in-out;
	}
}

.banner_wrapper {
	position: absolute;
	top: 1.25rem;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: flex-start;

	@include sm-down {
		top: 2rem;
	}

	@include xs-only {
		top: 1rem;
	}
}

.banner {
	z-index: 1;
	border-radius: 0.25rem;
	background: rgba(238, 234, 230, 0.2);
	backdrop-filter: blur(10px);

	width: 100%;
	margin: 0 2rem;

	max-width: 40rem;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.75rem;
	list-style: none;

	padding: 1rem;

	@include sm-down {
		max-width: 35rem;
		padding: 0.75rem 0.5rem;
	}

	@include xs-only {
		justify-content: center;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		width: auto;
		margin: 0;

		li {
			&:nth-child(3) {
				display: none;
			}

			&:nth-child(4) {
				display: none;
			}
		}
	}
}

.banner_benefit {
	display: flex;
	align-items: center;

	gap: 0.5rem;

	p {
		@include body5;

		line-height: 1;
	}

	svg {
		vertical-align: baseline;
	}

	@include sm-down {
		p {
			@include body6;
		}
	}
}
