@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	position: relative;
	width: 100%;
	height: 100%;
}

.media_stack {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: grid;
	grid-template-areas: 'center';

	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;

	> * {
		grid-area: center;
	}

	background: #f2f2f2;
}

.media_item {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	transition: transform 0.5s ease-in-out;

	img {
		width: 100%;
		height: 100%;
		object-position: center;
	}

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.pills {
	position: absolute;
	left: 24px;
	top: 24px;

	list-style: none;

	z-index: 1;
	display: flex;
	margin: 0;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;

	padding: 0;
	border-radius: 4px;

	@include xs-only {
		flex-direction: row;
		margin: 0 auto;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		justify-content: center;
		top: 15px;
	}
}

.pill {
	border-radius: 4px;
	display: flex;
	height: 25px;
	padding: 4px 12px;
	align-items: center;
	gap: 10px;
	background-color: $white;
	color: $text-grey-dark;
}
