@import 'styles/constants';
@import 'styles/colors';
@import 'styles/mixins/media-queries';

$text-arrow-space: 10px;
$shaft-width: 14px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.the_arrow {
	width: $shaft-width;
	transition: all 0.2s;

	&.pos_left {
		position: absolute;
		top: 50%;
		left: 0;

		> .shaft {
			width: 0;
			background-color: var(--baseColor);

			&::before,
			&::after {
				width: 0;
				background-color: var(--baseColor);
			}

			&::before {
				transform: rotate(0);
			}

			&::after {
				transform: rotate(0);
			}
		}
	}

	&.pos_right {
		> .shaft {
			width: $shaft-width;
			transition-delay: 0.2s;

			&::before,
			&::after {
				width: $arrow-head-width;
				transition: all 0.5s;
				transition-delay: 0.3s;
			}

			&::before {
				transform: rotate(40deg);
			}

			&::after {
				transform: rotate(-40deg);
			}
		}
	}

	> .shaft {
		position: relative;
		display: block;
		height: $shaft-thickness;
		background-color: var(--baseColor);
		transition: all 0.2s;
		transition-delay: 0s;
		will-change: transform;

		&::before,
		&::after {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			height: $arrow-head-thickness;
			background-color: var(--baseColor);
			transition: all 0.2s;
			transition-delay: 0s;
			content: '';
		}

		&::before {
			transform-origin: top right;
		}

		&::after {
			transform-origin: bottom right;
		}
	}
}

.animated_arrow {
	position: relative;
	display: inline-block;
	align-self: center;
	text-decoration: none;
	transition: all 0.2s;

	color: var(--baseColor);

	&:hover {
		text-decoration: none;

		&:not(.no_hover) {
			> .the_arrow.pos_left {
				> .shaft {
					width: $shaft-width;
					background-color: var(--baseColor);
					transition-delay: 0.1s;

					&::before,
					&::after {
						width: $arrow-head-width;
						background-color: var(--baseColor);
						transition-delay: 0.1s;
					}

					&::before {
						transform: rotate(40deg);
					}

					&::after {
						transform: rotate(-40deg);
					}
				}
			}

			> .main {
				transform: translateX($shaft-width + $text-arrow-space);

				> .the_arrow.pos_right {
					> .shaft {
						width: 0;
						transform: translateX(200%);
						transition-delay: 0s;

						&::before,
						&::after {
							width: 0;
							transition: all 0.1s;
							transition-delay: 0s;
						}

						&::before {
							transform: rotate(0);
						}

						&::after {
							transform: rotate(0);
						}
					}
				}
			}
		}
	}

	> .main {
		display: flex;
		align-items: center;
		transition: all 0.2s;

		> .text {
			font-size: 0.875rem;
			font-weight: 400;
			margin: 0 $text-arrow-space 0 0;
			line-height: 1;
		}

		> .the_arrow {
			position: relative;
		}
	}
}
